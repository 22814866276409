@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --primary: #ff4218;
  --primary-variant: #4380f7;
  --white: #ffffff;
  --delete: #c53030;
  --error: #fc2134;
  --disabled: #f8f8f8;
  --border: #b9b9b9;
}

html {
  height: 100%;
}

@font-face {
  font-family: 'ellipsis-font';
  src: local('system-ui');
  unicode-range: U+2026;
}

body {
  font-family: 'ellipsis-font', 'Noto Sans JP', sans-serif;
  color: rgb(var(--foreground-rgb));
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.56px;
  width: auto;
  background: #fffbf8;
  overflow: hidden;
}

textarea {
  resize: none;
}

input[type='text'] {
  font-family: 'Noto Sans JP', sans-serif;
}
p {
  line-height: 20px;
}

#root {
  height: 100%;
  position: relative;
}

tr {
  height: 40px;
}

input,
select {
  background-color: var(--white);
}

a.active {
  background-color: var(--primary);
  color: var(--white);
  > * {
    color: inherit;
    font-weight: bold;
  }
}

@layer components {
  .input-style {
    @apply border rounded w-full py-2 px-3 leading-tight focus:outline-none;

    &:focus {
      @apply ring;
    }
  }
  .webkit-appearance {
    -webkit-appearance: none;
  }
  .moz-appearance {
    -moz-appearance: textfield;
  }
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, -20px);
  }

  2% {
    transform: translate(-50%, 0px);
  }

  98% {
    transform: translate(-50%, 0px);
  }

  100% {
    transform: translate(-50%, -20px);
  }
}

.toast_cs {
  position: absolute;
  z-index: 10000;
  right: -14%;
  top: 40px;
  transform: translate(-50%, -20px);

  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-direction: normal;
}

.open-modal {
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.close-modal {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards !important;
}

@keyframes scaleUp {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes scaleDown {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

#custom-scroll::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track,
.custom-scroll-bigger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#custom-scroll::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.custom-scroll-bigger::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

#custom-scroll::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.custom-scroll-bigger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff4218;
}

.disabled {
  color: black !important;
  border: 1px solid var(--border);
}

.status_interview {
  padding: 8px !important;
  font-size: 14px !important;
  flex: 1;
  height: 42px;
  width: 100%;
  justify-content: center;
}

.reservable {
  border: 1px solid var(--primary);
  white-space: nowrap;
}

.underReservation,
.completion,
.cancel {
  border: 1px solid var(--border);
}

.notFbFinished {
  border: 1px solid var(--border);
  white-space: nowrap;
}

.interview {
  .ant-table-row {
    &.currentWeek {
      background: #ffede9;

      .ant-table-cell {
        p {
          color: #333 !important;
          font-weight: bold !important;
        }
      }
    }

    &.completed {
      background: #e7e7e7;
    }
  }
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: #f2f2f2 !important;
}

//
//canvas {
//  height: 687px !important;
//  width: 687px !important;
//  margin: auto;
//}

.border-custom-gray {
  border: 1px solid #b9b9b9 !important;
}

.hightly {
  background: #ffede9;
}

.test {
  display: none;
}

.message_contai:hover .test {
  display: flex;
}

.nowrapConsultant {
  .ant-select-selector {
    width: 474px;
    .ant-select-selection-overflow {
      flex-wrap: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ant-select-selection-overflow-item {
  .ant-select-selection-item-content {
    .ant-space-item {
      &:not(:nth-child(3)) {
        display: none;
      }
    }
  }
}

.ant-select-item-empty {
  text-align: center !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.previewToEmployee_self {
  height: calc(100% - 215px);
  .ant-row.ant-form-item-row {
    height: 100%;
    .ant-form-item-control-input {
      height: 100%;

      .ant-form-item-control-input-content {
        height: 100%;

        textarea.ant-input {
          height: 100% !important;
          max-height: 100% !important;
        }
      }
    }
  }
}

.implement-detail {
  .ant-dropdown-menu {
    &.ant-dropdown-menu-root {
      max-height: 311px !important;
      overflow-y: auto !important;
      width: 366px !important;
      padding: 8px 0 !important;
    }
  }
}

.animation {
  transition: all 0.5s ease-out;
}

.ck-content.ck-editor__editable {
  min-height: 200px;
}

@media screen {
  .data-to-print {
    visibility: hidden;
  }
}

.ck,
.contentCKEditor {
  h2,
  h3,
  h4 {
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  a {
    color: -webkit-link;
    text-decoration: underline;
  }

  p {
    line-height: unset;
  }

  blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .memo-text {
    font-size: 32px;
  }
}

.ck.ck-content {
  height: 200px;
  overflow-y: auto;
}

.custom_input {
  gap: 24px;
  span {
    font-size: 20px;
  }
  label {
    flex-direction: row;
  }
}

.custom_select_me {
  background: #f5f5f5;
  span {
    font-size: 16px !important;
    font-weight: bold !important;
    padding-right: 0 !important;
    display: flex;
    justify-content: center;
  }
}

.ck-powered-by {
  display: none !important;
}
.fulfillment-table-2 .ant-table .ant-table-thead .ant-table-cell,
.fulfillment-table-1 .ant-table .ant-table-thead .ant-table-cell {
  background-color: #ffffff !important;
}

.fulfillment-table-2 .ant-table-tbody > tr > td {
  padding: 16px !important;
}

.table-list-report .ant-table .ant-table-thead .ant-table-cell {
  text-align: start;
}
.table-list-report .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  text-align: start !important;
}
.fulfillment-table-1,
.fulfillment-table-2,
.table-list-report {
  width: 100%;
}

.fulfillment-table-1,
.fulfillment-table-2 {
  .ant-table-content > table > tbody > tr > td {
    border-inline-end: 1px solid #b9b9b9 !important;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-inline-end: 1px solid #b9b9b9 !important;
  }
  .ant-table-tbody tr:hover > td:not(:last-child) {
    background-color: unset !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #b9b9b9 !important;
  }
  .ant-table-thead > tr > th {
    //border-bottom: 1px solid #b9b9b9 !important;
  }
  .ant-table-thead > tr > td {
    border-bottom: 1px solid #b9b9b9 !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-inline-start: 1px solid #b9b9b9 !important;
    border-top: 1px solid #b9b9b9 !important;
    border-start-start-radius: initial;
    border-start-end-radius: initial;
  }
}

.fulfillment-table-2 {
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #b9b9b9 !important;
  }
}

.ant-table-wrapper.fulfillment-table-2
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > td {
  border-inline-end: 1px solid #b9b9b9 !important;
}

.custom-border-new {
  position: relative;
  border-top: 1px solid transparent;
  border-right: 1px solid #b9b9b9;
  border-bottom: none;
  border-left: 1px solid transparent;
}

.custom-border-new::after  {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #b9b9b9;
}


.custom-border-backup {
  position: relative;
  border-bottom: none !important;
}

.custom-border-backup::after {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #b9b9b9;
}

.custom-border-new-4type {
  border-top: 1px solid transparent;
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid transparent;
}

.custom-border-new-4type-header:last-child {
  border-right: 1px solid transparent;
}

.custom-border-new:last-child {
  border-right: 1px solid transparent;
}

.border-custom-header:last-child {
  border-right: 1px solid transparent;
}

.border-custom-header-empty:last-child {
  border-bottom: none;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  height: auto !important;
}

.cut-div {
  border-bottom: 1px solid !important;
}

.custom-strikethrough {
  position: relative;
}

.custom-strikethrough::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black; /* Màu của gạch ngang */
  transform: translateY(-50%);
  z-index: 1;
}

.custom-a {
  text-decoration: underline !important;
}

.custom-a > i > strong {
  text-decoration: underline !important;
}

.custom-a > strong {
  text-decoration: underline !important;
}

.custom_p_fs {
  font-size: 32px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
}

span.ant-layout-sider-zero-width-trigger {
  background: #ff4218 !important;
}
.page-break {
  page-break-before: always;
}
@media print {
  body {
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: landscape;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.15cm;
    margin-top: 0.25cm !important;
  }

  .page-break {
    padding: 32px 40px;
    page-break-before: always;
  }
  h1,
  h2,
  h3,
  p {
    page-break-inside: avoid;
  }

  h1,
  h2,
  h3 {
    page-break-before: avoid;
    page-break-after: avoid;
  }
}

.draw-line-table {
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:first-child {
    padding: 0 !important;
  }
}

.ant-table-wrapper .ant-table-sticky-scroll {
  opacity: 1;
  .ant-table-sticky-scroll-bar {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ff4218;
    height: 6px;
  }
  .ant-picker-content {
    tr {
      height: auto !important;
    }
  }
}

textarea.text-area-custom {
  min-height: 155px !important;
}