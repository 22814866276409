@font-face {
  font-family: 'ellipsis-font';
  src: local('system-ui');
  unicode-range: U+2026;
}

.spin {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.ant-table {
  border-radius: 0 !important;
  .ant-table-cell {
    border-radius: 0 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 18px !important;
      padding: 11px 8px 12px !important;
      letter-spacing: 0.08px !important;
      background-color: #e2e8f0 !important;
      color: #4a5568 !important;
      text-align: center;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        padding: 8px;
        &:not(:first-child) {
          text-align: center;
        }

        button {
          margin: auto;
        }
      }
    }
  }
}

.ant-table-container {
  table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
  }
}

[class*='ant'] {
  font-family: 'ellipsis-font', 'Noto Sans JP', sans-serif;
}

.ant-dropdown-menu {
  padding: 0 !important;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  .ant-space-item {
    font-size: 16px;
    color: #171923;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.64px;
  }
}

.implements {
  .ant-table-wrapper {
    .ant-table-content {
      table {
        padding-bottom: 1rem !important;

        .ant-table-cell {
          a:hover {
            color: #171923;
          }
        }
      }
    }
  }

  .ant-select-selector {
    .ant-select-selection-item {
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.08px;
    }
  }

  .ant-select-selection-overflow-item {
    padding-right: 8px;
  }

  .ant-picker-suffix {
    display: flex;
    margin-left: auto;
  }

  .ant-picker {
    .ant-picker-input {
      width: 120px;
      input {
        max-width: 100px;
        text-align: center;
      }
    }
    .ant-picker-active-bar {
      display: none;
    }
  }
}

.omoteura,
.history{
  .ant-table-thead {
    .ant-table-cell {
      padding: 12px 8px !important;
      background: white !important;
      font-weight: bold !important;
      color: #010101 !important;
      font-size: 16px !important;
      &:nth-child(1) {
        font-size: 24px !important;
        text-align: start;
      }

      &:nth-child(2) {
        color: #FF4218 !important;
        background: #FFEDE9 !important;

        &.noHighLight {
          color: #010101 !important;
          background: white !important;
        }
      }

      &.highLightHistory {
          color: #3CADFF !important;
          background: #EBF6FF !important;
          border: 4px solid #3CADFF !important;
          border-bottom: 1px solid #aaa !important;
      }

      &.noHighLight {
        color: #010101 !important;
        background: white !important;
      }

      &.ant-table-cell-scrollbar {
        display: none;
      }
    }
  }

  .inactive {
    &.ant-table-row {
      background: white;
    }
  }

  .ant-table {
    border-top: 1px solid var(--border);
  }

  th, td {
    border-color: var(--border) !important;
  }

  .ant-table-wrapper {
    border-left: 1px solid rgb(185, 185, 185) !important;
    border-bottom: 0 !important;
    .ant-table .ant-table-header {
      border-radius: 0 !important;
    }
  }
}

.history{
  &.addBorder {
    .ant-table-thead {
      .ant-table-cell {
        padding: 16px !important;
        min-width: 56px !important;
        &:nth-child(1) {
          font-size: 16px !important;
        }

        &:nth-child(2) {
          border: 4px solid #FF4218 !important;
          border-bottom: 1px solid #aaa !important;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          &:nth-child(2) {
            border-left: 4px solid #FF4218 !important;
            border-right: 4px solid #FF4218 !important;
          }
        }

        &:last-child {
          .ant-table-cell {
            &:nth-child(2) {
              border-bottom: 4px solid #FF4218 !important;
            }
          }
        }
      }
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        &.addBorderHistory {
          border-left: 4px solid #3CADFF !important;
          border-right: 4px solid #3CADFF !important;
        }
      }
      &:last-child {
        .ant-table-cell {
          &.addBorderHistory {
            border-bottom: 4px solid #3CADFF !important;
          }
        }
      }
    }
  }
}

.omoteura {
  .ant-table-thead {
    .ant-table-cell {
      &:nth-child(3) {
        color: #3CADFF !important;
        background: #EBF6FF !important;

        &.noHighLight {
          color: #010101 !important;
          background: white !important;
        }
      }
    }
  }

  &.commentary {
    .ant-table-thead {
      width: 100%;
      th.ant-table-cell {
        background: #E7E7E7 !important;
        width: calc(100% /2);
        color: black !important;
        font-size: 20px !important;
        font-weight: 700;
        line-height: normal !important;
        padding: 16px 8px !important;
        border-top: 1px solid;

        &:last-child {
          border-right: 1px solid;
        }
      }
    }

    .ant-table-wrapper {
      border: 0 !important;
      .ant-table {
        border-top: 0 !important;
      }
      .ant-table-cell:not(.ant-table-thead td) {
        border-left: 1px solid;
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell{
            &:first-child {
              border-left: 1px solid rgb(185, 185, 185) !important;
            }
            &:last-child {
              border-right: 1px solid;
            }
          }
        }
      }
    }
  }
}

.tab2-history {
  .ant-table-wrapper {
    .ant-table-content {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      .ant-table-thead {
        .ant-table-cell {
          background-color: #F5F5F5 !important;
        }
      }
    }
  }
}

.ant-select {
  margin-bottom: 0 !important;
  .ant-select-selector {
    height: 40px !important;
    border-color: #718096 !important;
    .ant-select-selection-placeholder {
      padding-top: 3px !important;
      font-weight: bold;
    }
    .ant-select-selection-search,
    .ant-select-selection-item {
      color: #171923 !important;
      margin: auto;
      font-size: 16px;
    }
  }
  .ant-select-clear {
    height: 15px;
    width: 15px;
  }
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 4px;
  }
  .ant-select-selection-overflow::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .ant-select-selection-overflow::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }
  .ant-select-selection-overflow::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ff4218;
  }
  .ant-select-arrow {
    color: #171923 !important;

     .anticon-down {
       svg {
         width: 10px !important;
         height: 10px !important;
       }
     }
  }
}

textarea.ant-input {
  min-height: 80px !important;
}

.ant-popover,
.ant-select-dropdown,
.ant-picker-dropdown,
.ant-dropdown{
  z-index: 1100;
}

.ant-select-dropdown {
  padding: 8px 0;
  .ant-select-item {
    height: 40px;
    padding: 8px 12px !important;
    .ant-select-item-option-content {
      .check-box {
        display: none;
      }
      .noCheckbox {
        display: block;
      }
      .ant-space {
        gap: 0 !important;
        .ant-space-item {
          height: 40px;
        }
      }
      .ant-space-item:last-child {
        padding-left: 8px;
      }
    }
    &.ant-select-item-option-selected{
      background: rgb(181 149 142 / 20%);
      .ant-select-item-option-content {
        .check-box {
          display: block;
        }
        .noCheckbox {
          display: none;
        }
      }
    }
    .ant-select-item-option-state {
      display: none;
    }
    &.ant-select-item-option-active {
      background: rgba(255,66,24,0.2);
    }
  }
}


.ant-check-input {
  .ant-checkbox-inner {
    color: var(--primary);
  }
}

.login {
  .ant-form-item {
    margin-bottom: 0 !important;
  }

  label {
    margin-bottom: 8px !important;
  }
}

.ant-form-item {
  margin-bottom: 4px;
}

.ant-picker .ant-picker-input >input {
  color:  #171923;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.employ-management {
  .ant-select {
    width: 100% !important;
    color: red !important;

    .ant-select-selector {
      border-color: #718096 !important;
      border-radius: 6px !important;
      padding: 0 12px !important;

      .ant-select-selection-search,
      .ant-select-selection-item {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0.64px !important;
        color: #171923 !important;
        text-align: start !important;
      }
    }
  }
}

ul.ant-dropdown-menu {
  padding: 0 !important;
}

.implement-detail {
  li {
    &:hover {
      background-color: #E7E7E7 !important;
    }
  }
  li:last-child {
    padding: 8px !important;
    cursor: context-menu;
    span {
      display: flex;
      justify-content: end;
    }

    &:hover {
      background: transparent !important;
    }
  }
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    padding: 8px 0px;
    display: flex;
    gap: 8px;
    width: fit-content;
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: transparent;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: transparent;
}

.search {
  cursor: pointer;
  .ant-input {
    border: none;
    padding: 0;
    &:focus {
      box-shadow: none !important;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #718096;
    border-radius: 6px !important;
    padding: 8px 12px;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;

    input {
      color: #171923;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.64px;
    }

    .ant-input-suffix {
      margin: 0;
    }
  }
  .ant-input-group-addon {
    display: none;
  }
}

.ant-pagination {
  display: flex;
  justify-content: end;
  margin-top: 24px;
}

.inactive {
  &.ant-table-row {
    background: rgba(0, 0, 0, 0.08);
    .ant-table-cell {
      .action {
        pointer-events: auto !important;
      }
      a {
        opacity: 0.6;
      }
    }
  }
}

.ant-table-tbody >tr.ant-table-row.inactive:hover>td {
  background: rgba(0, 0, 0, 0.08) !important;
}



.anticon.anticon-delete {
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #718096;
    border-radius: 6px;
    padding: 2px;
  }
}

.ant-upload-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;

  .ant-upload.ant-upload-drag {
    height: 100px;
  }

  .ant-upload-list {
    .ant-upload-list-item-name {
      color: #171923;
      font-size: 18px;
      line-height: 22px;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      text-decoration: underline;
      text-underline-offset: 1px;
    }
  }
}

.radio-custom {
  .ant-radio-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }
}

.custom-radio-wrap {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: end;
    gap: 16px;
  }
}

.ant-radio-wrapper {
  .ant-radio-inner {
    width: 26px;
    height: 26px;
    border-color: #000;
    border-width: 3px;
  }
}

.radio-custom2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notHover {
  .ant-table-cell-row-hover {
    background-color: transparent !important;
  }
}

.my-page {
  .ant-table-thead{
    .ant-table-cell {
      white-space: nowrap;
    }
  }
}

.highlighted-row {
  border: 1px solid red;
}

.inputEditCommon {
   .ant-form-item {
     margin-bottom: 0 !important;
     &:first-child {
       width: 100%;
     }
  }
}

.ant-input-group-wrapper {
  padding: 0 !important;
  .ant-input-wrapper {
    height: 100%;
    color: #333333;
    .ant-input-group-addon {
      border: none;
      background: white;
    }

    .ant-input {
      height: 100%;
      border: none;
      border-left: 1px solid transparent;
    }
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #718096 !important;
}

.ant-input-disabled,
.ant-picker-disabled,
.ant-input-affix-wrapper-disabled {
  background-color: rgba(0, 0, 0, 0.02) !important;
  color: rgba(0, 0, 0, 0.75) !important;
}

.ant-picker-disabled {
  .ant-picker-input {
    input {
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }
}

.ant-input-disabled:hover,
.ant-input[disabled]:hover,
.ant-picker-disabled:hover,
.ant-input-affix-wrapper-disabled {
  border-color: #d9d9d9 !important;
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgba(0, 0, 0, 0.02) !important;
  }
  .ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.75) !important;
  }
}

.ant-select-disabled {
  .ant-select-selector {
    border-color: #d9d9d9 !important;
    background-color: rgba(0, 0, 0, 0.02) !important;
    .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.75) !important;
    }
  }
}

.notfoundPage {
  height: 100%;
  .ant-image {
    height: 100%;
    display: flex;
  }
}

.ant-dropdown {
  .menu1 {
    width: 200px;
    border-radius: 0;
    box-shadow: none;
  }
}


.ant-dropdown-menu-submenu {
  .ant-dropdown-menu-submenu-title {
    .ant-dropdown-menu-title-content {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #333;
    }
  }

  &.ant-dropdown-menu-submenu-open {
    background: #B9B9B9;
  }
  .ant-dropdown-menu {
    width: 200px;
  }

  .ant-dropdown-menu-sub {
    .ant-dropdown-menu-submenu-title {
      margin-left: 20px !important;
    }
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        padding: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.displayNone {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.disabledInput {
  background-color: rgba(0, 0, 0, 0.02) !important;
  color: rgba(0, 0, 0, 0.75) !important;
}

.setting-group:hover .anticon-setting {
  color: #FF4218
}

.ant-table-body {
  background: #ffffff;
}

#root > div.ant-spin-nested-loading {
  height: 100%;
  width: 100%;
  div.ant-spin-container {
    height: 100%;
    width: 100%;
  }
  div.ant-spin {
    max-height: 100vh !important;
  }
}

.ant-spin-dot-spin{
  margin-top: -10px !important;
}

.implementModalForm .ant-select .ant-select-selection-overflow::-webkit-scrollbar{
  height: 3px;
}

.modalErrorNetWork {
  &.ant-modal {
    width: 450px !important;
  }

 .ant-modal-title {
   text-align: center;
   font-weight: bold;
   font-size: 16px;
 }
}
